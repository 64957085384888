<template>
  <el-container class="box">
    <el-header>
      <div style="display: flex;align-items:center;justify-content: flex-start;width:50%;">
        <img src="@/assets/logo-login.png" class="bar-logo" />
        <span>共幸九华保管理后台</span>

      </div>
      <div style="display: flex;align-items:center;justify-content: flex-end;width:50%;">
        <el-dropdown style="float:right;">

          <span class="el-dropdown-link">
            {{ organizationName }} {{ sysName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item @click.native="changePwd">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="menu-box">
      <el-aside width="180px" style="background:#fff">
        <el-scrollbar>
          <el-menu style="border-right:none!important;" :default-active="defaultActive" text-color="#000"
            background-color="#fff" :unique-opened="true">
            <div v-for="(item, index) in menuTree" :key="'key' + index">
              <el-menu-item v-show="item.path" :index="index + ''" @click.native="menuClick(item.path)"
                v-text="item.label"></el-menu-item>
              <el-submenu :index="index + ''" v-show="!item.path">
                <template slot="title">
                  <span class="menu-title">{{ item.label }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="(item2, index2) in item.child" :key="index2"
                    @click.native="menuClick(item2.path)" :index="index + '-' + index2 + ''" v-text="item2.label">
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </div>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container class="box2">
        <div class="right-main-body">
          <el-main>
            <div class="main-box">
              <router-view :key="$route.fullPath"></router-view>
            </div>
          </el-main>
          <div class="copyright">
            <a target="_blank" href="https://beian.miit.gov.cn/">共幸科技 ( 粤ICP备20066053号) ©2019-2022 本网站链接 共幸科技（深圳）有限公司
              版权所有</a>
          </div>
        </div>
      </el-container>
    </el-container>
    <ChangePassword :show.sync="changePwdDialog" :sysId="sysId"></ChangePassword>
  </el-container>
</template>
<script>
import ChangePassword from '@/components/ChangePassword.vue'
import { getCookies, setCookies, removeCookies } from "@/utils/utils";
export default {
  name: "index",
  components: { ChangePassword },
  data() {
    return {
      TabsMenuValue: "/default",
      TabsMenuArr: [],
      changePwdDialog: false,
      sysId: getCookies("sysId"),
      sysName: getCookies("sysName"),
      organizationName: getCookies("organizationName"),
      sysPhone: getCookies("sysPhone"),
      roleId: getCookies("roleId"),
      menuTree: getCookies("sysMenus") ? getCookies("sysMenus") : [],

    };
  },
  computed: {
    getTabsMenuArr() {
      return this.$store.state.TabsMenuArr;
    },
    getTabsMenuValue() {
      return this.$store.state.TabsMenuValue;
    },

    defaultActive() {
      /** tree 节点默认展开 */
      let index = this.menuTree.findIndex(
        (item) => item.path == this.TabsMenuValue
      );
      // 从1层数组中查询path
      if (index >= 0) {
        return index + "";
      } else {
        for (let i = 0, item; (item = this.menuTree[i++]);) {
          //遍历一层数组
          index = item.child.findIndex(
            (item) => item.path == this.TabsMenuValue
          );
          // 从child数组中查询
          if (index >= 0) {
            return i - 1 + "-" + index;
          }
        }
      }
    },
  },
  watch: {
    getTabsMenuArr(val) {
      this.TabsMenuArr = val;
    },
    getTabsMenuValue(val) {
      this.TabsMenuValue = val;
    },
  },
  mounted() {
    this.$router.push({ path: this.$store.state.TabsMenuValue || "/default" });
    this.TabsMenuArr = this.$store.state.TabsMenuArr;
    this.TabsMenuValue = this.$store.state.TabsMenuValue;
    console.log('this.menuTree');
    console.log(this.menuTree);

  },
  methods: {
    changePwd() {
      this.changePwdDialog = true;
    },
    // tabs 顶部菜单栏点击跳转title页头
    clickTab(tab, event) {
      if (
        JSON.stringify(getCookies("TabsMenuValue")).indexOf(tab.paneName) == -1
      ) {
        this.$router.push({ path: tab.paneName });
      }
    },
    // tabs 顶部菜单栏点击关闭title页头
    removeTab(targetName) {
      this.$store.dispatch("removeRouterPath", { name: targetName });
    },
    // 左侧菜单 点击跳转
    menuClick(path) {
      this.$router.push({ path: path });
    },
    //退出登录
    logOut() {
      this.TabsMenuArr = [];
      this.$store.dispatch("removeCookiesStorage");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/base";

.box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .box2 {
    height: calc(100vh - 60px);
    overflow: auto;
  }
}

.menu-box {
  overflow-y: auto;
}

.el-dropdown {
  cursor: pointer;
}

.bar-logo {
  height: 54px;
  background: #fff;
}

.menu-title {
  font-size: 16px;
  line-height: 45px;
}

.main-box {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.el-container {
  height: 100%;
}

.el-header {
  background-color: $themeColor;
  // background-color: #000;

  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.el-header span {
  color: #ffffff;
}

.el-tab-pane {
  font-size: 16px;
}

.el-container .el-aside,
.el-menu {
  // background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.right-main-body {
  width: 100%;
  color: #333;
  // text-align: center;
}

.copyright {
  text-align: center;

  padding: 20px 0;

  a {
    color: #444444;
    font-size: 13px;
    text-decoration: none;

    &:hover {
      color: red;
    }
  }
}

.el-main {
  background-color: #ffffff;
  color: #333;
  // text-align: center;
  padding-top: 5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  border-left: 1px solid #f1f1f1;
  min-height: 93%;
}

.el-submenu,
.el-menu-item {
  text-align: left;
}

.el-submenu__title {
  padding-left: 10px !important;
}

.el-menu-item-group {
  overflow-x: hidden;
  // background: #000;
}

.el-menu-item-group .el-menu-item {
  padding-left: 34px !important;
}

.el-menu-item {
  text-align: left;
  padding: 0px 0px 0px 20px !important;
}

.el-submenu .el-menu-item {
  min-width: 154px;
}

.el-menu-item.is-active {
  border-right: 4px solid $themeColor !important;
}

body>.el-container {
  margin-bottom: 40px;
}
</style>